/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import { Summary } from '../App.style';

export default () => (
  <>
    <Summary>
      My name is Mack. I am currently a Senior Full Stack Developer and
      Architect at Nationale Nederlanden in Amsterdam. I was formerly the Lead
      Mobile Developer at Prodigy Education where I had the pleasure of meeting
      several Expo Team members through your community on Slack. I'm reaching
      out today because I'd like to join the Expo team.
    </Summary>
    <br />
    <Summary>
      Overall, I have over 10 years experience in full stack and mobile
      development. I am helping my company modernize their development culture
      and hire more engineers who share a similar mindset. I'm also leading
      efforts to introduce automated simulator testing, visual regression
      testing, and a shared design system.
    </Summary>
    <br />
    <Summary>
      During my position at Prodigy, I designed and carried out a migration plan
      for a vanilla react-native app to a managed Expo project while continuing
      to support millions of active users. After, I developed and made sure new
      features continue to work on a wide range of OS versions and devices. I
      also setup a CI/CD system with turtle and helped the Software Mansion
      developers fix a few bugs within it.
    </Summary>
    <br />
    <Summary>
      I really enjoy working on open source development. I like to help
      companies I work for understand the value of resources they are getting
      for free, which gives me opportunities to contribute back. I've
      contributed to react-native, expo and typescript in the past. More
      recently, I've had the opportunity to contribute a major feature to
      react-native-ui-lib. I also enjoy participating in the local community by
      giving tech talks on technologies I like. Joining Expo could enable more
      opportunities for me to contribute to the community, while at the same
      time promote and raise awareness of Expo.
    </Summary>
    <br />
    <Summary>
      I believe with my background and skill set I could be a great addition to
      the Expo team. Thank you for reading and I hope to hear from you soon!
    </Summary>
    <br />
  </>
);
